<template>
  <div>
    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Document Type</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="500">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Document Type Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <!--Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_name"
                    label="Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Type-->
                <v-col cols="12" sm="12">
                  <v-select
                    :items="types"
                    item-text="name"
                    item-value="id"
                    label="Section"
                    placeholder=" "
                    persistent-placeholder
                    v-model="f_type"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Sort-->
                <v-col cols="12" sm="12" md="3">
                  <v-text-field
                    v-model="d_sort"
                    label="Sort"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Status-->
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "documentTypesStore/getEntryField",
  mutationType: "documentTypesStore/updateEntryField"
});

export default {
  name: "DocumentTypeEntry",
  computed: {
    ...mapState({
      documentTypesStore: state => state.documentTypesStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields(["show", "valid", "mode", "id", "d_name", "d_sort", "f_type", "f_status"])
  },
  data() {
    return {
      types: [
        {
          id: 1,
          name: "Provider"
        },
        {
          id: 2,
          name: "Client"
        },
                {
          id: 3,
          name: "Teacher"
        }
      ]
    };
  },
  methods: {
    async newEntry() {
      this.valid = true;
      this.mode = 1;
      this.id = "";
      this.d_name = "";
      this.d_sort = "";
      this.f_type = "";
      this.f_status = true;
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["documentTypesStore/getEntry"];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch("documentTypesStore/create", entry);
        } else {
        /* Edit */
          await this.$store.dispatch("documentTypesStore/update", entry);
        }
        this.cancelEntry();
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
